//------------- Theme-variables-----------
:root {
    --font_0: Lato;
    --font_1: sans-serif;
    --font-themify: themify;
    --font-awesome: FontAwesome;


    //---Theme-bg--Theme-Change--//
    --theme-bg : #ffffff;

    //---- Header-BG
    --header-bg : #0267b9;

    //---- Sticky-Header
    --header-sticky-bg : #0246ac;

    // ---- Top-Header
    --top-header-bg : #fff4f4;
    --top-header-color : #000000;
    --top-header-hover : #fa3a3a;

    // Header-menu
    --header-menu-normal : #ffffff;
    --header-menu-sticky : #ffffff;
    --header-menu-hover-color : #ff6620;

    --mobile-menu-bg : #000;
    --mobile-menu-item-color : #fff;


    // ----Footer-bg
    --footer-bg : #313131;
    --sub-footer-bg : #646464;

    --newsletter-bg : #5a5a5a;
    --newsletter-border : #cfcfcf;

    --footer-title : #fff;
    --footer-item : #eeeeee;
    --footer-item-hover : #fd7d27;
    --sub-footer-color : #fff;

    --footer-logo-filter : brightness(100);


    // Theme-content

    --h1-color : #000;
    --h2-color : #000;
    --h3-color : #000;
    --h4-color : #000;
    --h5-color : #000;
    --h6-color : #000;
    --p-color : #000;
    --ul-li-color : #000;
    --a-color : #000;
    --span-color : #000;

    // collection-filter-block
    --collection-filter-bg : #f3f3f3;
    --collection-title : #000000;
    --collection-filter-a : #000000;
    --collection-filter-label : #000000;
    --collection-title-after : #000000;
    --collection-filter-img : brightness(1);

    //---pagination
    --pagination-a-bg : #ddd;
    --pagination-a-color : #000;
    --pagination-span-color : #000;
    --pagination-active-bg : #fff;

    //---breadcrumb-section
    --breadcrumb-bg : #000;
    --breadcrumb-color : #fff;
    --breadcrumb-active-color : #ffe2e2;


    --size-btn-bg : #fff;
    --size-btn-color : #000;
    --size-btn-active-bg : #000;
    --size-btn-active-color : #fff;
    --btn-border-color : #000;

    --theme-btn-bg : #fff;
    --theme-btn-color : #000;
    --theme-btn-border : #000;
    --theme-btn-hover-bg : #000;
    --theme-btn-hover-color : #fff;

    --btn-solid-bg : #000;
    --btn-solid-color : #fff;
    --btn-solid-hover-bg : #f1f1f1;
    --btn-solid-hover-color : #000;
    --btn-solid-border : #000000;

    --btn-primary-bg : #000;
    --btn-primary-color : #fff;
    --btn-primary-border : #000;
    --btn-primary-hover : #242424;

    //---accordion

    --accordion-bg : #000;
    --accordion-color : #fff;

    --qty-box-bg : #ccc;
    --qty-box-color : #000;
    --qty-box-no-bg : #fff;
    --qty-box-no-color : #000;


    --subscribe-form-bg : #000000;
    --subscribe-form-color : #fff;
    --subscribe-form-border : #6d6d6d;

    --bottom-top-btn-bg : #000;
    --bottom-top-btn-color : #fff;
    --bottom-top-btn-border : #797979;

    --mobile-back-span : #000;
    --mobile-back-btn : #fff;

    //---font-icon
    --top-header-icon : #000;
    --header-icon : #000;
    --footer-icon : #fff;
    --page-inner-icon : #000;

    //---Login-singup-pg
    --login-card-bg : #f1f1f1;
    --login-card-color : #000;
    --login-card-border : #ccc;

    --login-input-bg : #f1f1f1;
    --login-input-color : #000;
    --login-input-border : #f1f1f1;
    --login-input-border-left : #000000;

    --product-box-border : #e9e9e9;

    //---- After-login

    --dashboard-bg : #ececec;
    --dashboard-color : #000;
    --dashboard-active-color : #fd4e4e;
    --bordercolor : #ccc;
    --dashboard-edit : #fc1111;

    --input-form-control-bg : #f1f1f1;
    --input-form-control-color : #000000;
    --input-form-control-border : #ddd;

    --avalibility-stock : #06da77;


    //---Extra-colors

    --white: #ffffff;
    --white-dark: #eeeeee;
    --black: #000000;
    --dark-font: #222222;
    --grey-dark: #2d2a25;
    --font-color: #333333;
    --grey: #777777;
    --grey-light: #a1a1a1;
    --border: #dddada;
    --round-border: #dddddd;
    --grey-lighter: #f9f9f9;
    --theme-deafult: #ff4c3b;
    --grey-darken: #393230;
    --grey-link: #948e8c;
    --grey-font: #938d8c;
    --dark-footer: #2e2726;
    --form-bg: #f5f2f2;
    --grey-shade: #7f786d;
    --darker-footer: #25221e;
    --border-color: #38352f;
    --border-grey: #f1f5f4;
    --font-grey: #aaaaaa;
    --star-yellow: #ffa200;
    --border-bottom-g: #525252;
    --top-header: #f8f8f8;
    --header-font: #999999;
    --shadow: #ededed;
    --box-border: rgba(236, 236, 236, 0.6588235294117647);
    --grey-about: #f7f7f7;
    --grey2: #555555;
    --grey3: #efefef;
    --grey4: #444444;
    --grey5: #888888;
    --grey6: #6f6f6f;
    --sidebar-border: #f5f2f2;
    --sidebar-color: #938d8c;
    --modal: #ffba00;
    --bg-color: #d0edff;
    --bg-color1: #f1e7e6;
    --bg-color2: #bfbfbf;
    --light-grey: #d0edff;
    --light-grey: #d0edff;
    --pink: #ffc0cb;
    --red: #ff647f;
    --green: #6fb866;
    --yellow: #f2f896;
    --skyblue: #7ee7ec;
    --blue: #63b4f2;
    --orange: #ffb17a;
    --purple: #eeb1d7;
    --violet: #a06da0;
    --brown: #a57372;
    --lightgreen: #90ee90;
    --olive: #808000;
    --navy: #505c80;
    --maroon: #804f56;
    --icon: #6f6f6f;
    --grey-about: #f7f7f7;
    --grey01: #111111;
    --grey2: #555555;
    --grey3: #efefef;
    --grey4: #444444;
    --grey5: #888888;
    --grey6: #6f6f6f;
    --grey7: #40494f;
    --grey8: #c0c0c0;
    --left-sidebar: #2b2b2b;
    --dark-body: #2b2b2b;
    --dark-top: #1f1f1f;
    --color-red: #ff4c3b;
    --gradient1: #01effc;
    --gradient2: #485ff2;

    // dark layput variable //

    --dark-body: #2b2b2b;
    --dark-top: #232323;
    --dark-border: #404040;
    --dark-link: #cbcbcb;
    --dark-span: #929292;
    --dark-footer-bg: #383838;

    --white-1: #f1f3f5;
    --white-2: #eaedef;
    --white-3: #e7eaec;
    --white-4: #dee2e6;
    --white-5: #cfd4da;
    --white-6: #dddddd;
    --white-7: #e7e7e7;

    --grey9: #282828;
    --grey10: #afd3ff;

    --green-2: #03c169;
    --black-grey-1 : #141414;


}

//---fonts
$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$assetspath: "/assets/images";


//---Theme-bg--Theme-Change--//
$theme-bg : var(--theme-bg);

//---- Header-BG
$header-bg : var(--header-bg);

//---- Sticky-Header
$header-sticky-bg :var(--header-sticky-bg);

// ---- Top-Header
$top-header-bg :var(--top-header-bg);
$top-header-color :var(--top-header-color);
$top-header-hover :var(--top-header-hover);

// Header-menu
$header-menu-normal : var(--header-menu-normal);
$header-menu-sticky : var(--header-menu-sticky);
$header-menu-hover-color : var(--header-menu-hover-color);

$mobile-menu-bg : var(--mobile-menu-bg);
$mobile-menu-item-color : var(--mobile-menu-item-color);


// ----Footer-bg
$footer-bg : var(--footer-bg);
$sub-footer-bg : var(--sub-footer-bg);

$newsletter-bg : var(--newsletter-bg);
$newsletter-border : var(--newsletter-border);

$footer-title : var(--footer-title);
$footer-item : var(--footer-item);
$footer-item-hover : var(--footer-item-hover);
$sub-footer-color : var(--sub-footer-color);

$footer-logo-filter : var(--footer-logo-filter);


// Theme-content

$h1-color : var(--h1-color);
$h2-color : var(--h2-color);
$h3-color : var(--h3-color);
$h4-color : var(--h4-color);
$h5-color : var(--h5-color);
$h6-color : var(--h6-color);
$p-color : var(--p-color);
$ul-li-color : var(--ul-li-color);
$a-color : var(--a-color);
$span-color : var(--span-color);

// collection-filter-block
$collection-filter-bg : var(--collection-filter-bg);
$collection-title : var(--collection-title);
$collection-filter-a : var(--collection-filter-a);
$collection-filter-label : var(--collection-filter-label);
$collection-title-after : var(--collection-title-after);
$collection-filter-img : var(--collection-filter-img);

//---pagination
$pagination-a-bg : var(--pagination-a-bg);
$pagination-a-color : var(--pagination-a-color);
$pagination-span-color : var(--pagination-span-color);
$pagination-active-bg : var(--pagination-active-bg);

//---breadcrumb-section
$breadcrumb-bg : var(--breadcrumb-bg);
$breadcrumb-color : var(--breadcrumb-color);
$breadcrumb-active-color : var(--breadcrumb-active-color);


$size-btn-bg : var(--size-btn-bg);
$size-btn-color : var(--size-btn-color);
$size-btn-active-bg : var(--size-btn-active-bg);
$size-btn-active-color : var(--size-btn-active-color);
$btn-border-color : var(--btn-border-color);

$theme-btn-bg : var(--theme-btn-bg);
$theme-btn-color : var(--theme-btn-color);
$theme-btn-border : var(--theme-btn-border);
$theme-btn-hover-bg : var(--theme-btn-hover-bg);
$theme-btn-hover-color : var(--theme-btn-hover-color);

$btn-solid-bg : var(--btn-solid-bg);
$btn-solid-color : var(--btn-solid-color);
$btn-solid-hover-bg : var(--btn-solid-hover-bg);
$btn-solid-hover-color : var(--btn-solid-hover-color);
$btn-solid-border : var(--btn-solid-border);

$btn-primary-bg : var(--btn-primary-bg);
$btn-primary-color : var(--btn-primary-color);
$btn-primary-border : var(--btn-primary-border);
$btn-primary-hover : var(--btn-primary-hover);

//---accordion

$accordion-bg : var(--accordion-bg);
$accordion-color : var(--accordion-color);

$qty-box-bg : var(--qty-box-bg);
$qty-box-color : var(--qty-box-color);
$qty-box-no-bg : var(--qty-box-no-bg);
$qty-box-no-color : var(--qty-box-no-color);


$subscribe-form-bg : var(--subscribe-form-bg);
$subscribe-form-color : var(--subscribe-form-color);
$subscribe-form-border : var(--subscribe-form-border);

$bottom-top-btn-bg : var(--bottom-top-btn-bg);
$bottom-top-btn-color : var(--bottom-top-btn-color);
$bottom-top-btn-border : var(--bottom-top-btn-border);

$mobile-back-span : var(--mobile-back-span);
$mobile-back-btn : var(--mobile-back-btn);

//---font-icon
$top-header-icon : var(--top-header-icon);
$header-icon : var(--header-icon);
$footer-icon : var(--footer-icon);
$page-inner-icon : var(--page-inner-icon);

//---Login-singup-pg
$login-card-bg : var(--login-card-bg);
$login-card-color : var(--login-card-color);
$login-card-border : var(--login-card-border);

$login-input-bg : var(--login-input-bg);
$login-input-color : var(--login-input-color);
$login-input-border : var(--login-input-border);
$login-input-border-left : var(--login-input-border-left);

$product-box-border : var(--product-box-border);

//---- After-login

$dashboard-bg : var(--dashboard-bg);
$dashboard-color : var(--dashboard-color);
$dashboard-active-color : var(--dashboard-active-color);
$bordercolor : var(--bordercolor);
$dashboard-edit : var(--dashboard-edit);

$input-form-control-bg : var(--input-form-control-bg);
$input-form-control-color : var(--input-form-control-color);
$input-form-control-border : var(--input-form-control-border);

$avalibility-stock : var(--avalibility-stock);


//---Extra-colors

$white : var(--white);
$white-dark : var(--white-dark);
$black : var(--black);
$dark-font : var(--dark-font);
$grey-dark : var(--grey-dark);
$font-color : var(--font-color);
$grey : var(--grey);
$grey-light : var(--grey-light);
$border : var(--border);
$round-border : var(--round-border);
$grey-lighter : var(--grey-lighter);
$theme-deafult : var(--theme-deafult);
$grey-darken : var(--grey-darken);
$grey-link : var(--grey-link);
$grey-font : var(--grey-font);
$dark-footer : var(--dark-footer);
$form-bg : var(--form-bg);
$grey-shade : var(--grey-shade);
$darker-footer : var(--darker-footer);
$border-color : var(--border-color);
$border-grey : var(--border-grey);
$font-grey : var(--font-grey);
$star-yellow : var(--star-yellow);
$border-bottom-g : var(--border-bottom-g);
$top-header : var(--top-header);
$header-font : var(--header-font);
$shadow : var(--shadow);
$box-border: rgba(236, 236, 236, 0.6588235294117647);
$grey-about : var(--grey-about);
$grey2 : var(--grey2);
$grey3 : var(--grey3);
$grey4 : var(--grey4);
$grey5 : var(--grey5);
$grey6 : var(--grey6);
$sidebar-border : var(--sidebar-border);
$sidebar-color : var(--sidebar-color);
$modal : var(--modal);
$bg-color : var(--bg-color);
$bg-color1 : var(--bg-color1);
$bg-color2 : var(--bg-color2);
$light-grey : var(--light-grey);
$light-grey : var(--light-grey);
$pink : var(--pink);
$red : var(--red);
$green : var(--green);
$yellow : var(--yellow);
$skyblue : var(--skyblue);
$blue : var(--blue);
$orange : var(--orange);
$purple : var(--purple);
$violet : var(--violet);
$brown : var(--brown);
$lightgreen : var(--lightgreen);
$olive : var(--olive);
$navy : var(--navy);
$maroon : var(--maroon);
$icon : var(--icon);
$grey-about : var(--grey-about);
$grey01 : var(--grey01);
$grey2 : var(--grey2);
$grey3 : var(--grey3);
$grey4 : var(--grey4);
$grey5 : var(--grey5);
$grey6 : var(--grey6);
$grey7 : var(--grey7);
$grey8 : var(--grey8);
$left-sidebar : var(--left-sidebar);
$dark-body : var(--dark-body);
$dark-top : var(--dark-top);
$color-red : var(--color-red);
$gradient1 : var(--gradient1);
$gradient2 : var(--gradient2);

// dark layput variable //

$dark-body : var(--dark-body);
$dark-top : var(--dark-top);
$dark-border : var(--dark-border);
$dark-link : var(--dark-link);
$dark-span : var(--dark-span);
$dark-footer-bg : var(--dark-footer-bg);

$white-1 : var(--white-1);
$white-2 : var(--white-2);
$white-3 : var(--white-3);
$white-4 : var(--white-4);
$white-5 : var(--white-5);
$white-6 : var(--white-6);
$white-7 : var(--white-7);

$grey9 : var(--grey9);
$grey10 : var(--grey10);

$green-2 : var(--green-2);
$black-grey-1 : var(--black-grey-1);